import foreground from './foreground';
import appMixin from './app-mixin';

const mixin = {
  mixins: [appMixin],
  created() {
    this.foregroundBindings = [];
  },
  methods: {
    foregroundAdd(attrs = {}) {
      foreground.add(this, this.foregroundBindings, attrs);
    },
    foregroundRemove() {
      foreground.remove(this);
    },
    foregroundFocus() {
      foreground.focus(this);
    },
  },
};

export default mixin;
