import Base from './base';

export default class Customers extends Base {
  $relay() {
    return 'nexus';
  }

  async read(domain, username) {
    return this.$request(
      'get',
      `customers?domain=${domain}&filter[username]=${username}`
    );
  }

  async readOrders(domain, username, page = 1) {
    return this.$request(
      'get',
      `orders?domain=${domain}&filter[username]=${username}&page=${page}`
    );
  }
}
