import { createRouter, createWebHistory } from 'vue-router';
import _ from 'lodash';
import app from '@/app';
import routes from './routes';
import useFormStore from '@/stores/form';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// sets page title
router.beforeEach((to, from, next) => {
  let title = 'CONSOLE';

  if (to?.meta.title) {
    title += ` - ${to.meta.title}`;
  } else if (to.name) {
    title += ` - ${_.capitalize(to.name)}`;
  }

  const formStore = useFormStore();
  if (formStore.form({ customKey: from.path })) {
    formStore.reset({ customKey: from.path });
  }

  document.title = title;
  next();
});

app.use(router);

export default router;
