import Cookies from 'js-cookie';

// test for localStorage support
let modern = true;
try {
  const t = '@@';
  localStorage.setItem(t, '1');
  localStorage.removeItem(t);
} catch (e) {
  modern = false;
}

class Storage {
  constructor(options = {}) {
    // set up options
    this.options = {
      session: false,
      smartParse: true,
      forceCookies: false,
      ...options,
    };

    this.defaultExpiry = this.options.session ? 0.5 : 30;
    this.modern = modern && !this.options.forceCookies;
  }

  getItem(key) {
    if (!this.modern) return this.parseGet(Cookies.get(key));

    const response = this.options.session
      ? sessionStorage.getItem(key)
      : localStorage.getItem(key);

    try {
      const json = JSON.parse(response);
      const { _storageDate: date, _storageExpires: expires, data } = json;
      if (!date && !expires) return this.parseGet(response);

      const now = Math.round(new Date().getTime() / 1000);
      return now - date < expires ? this.parseGet(data) : null;
    } catch (e) {
      // i guess it's just a normal entry
      return this.parseGet(response);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  parseGet(value) {
    if (!this.options.smartParse || typeof value === 'object') {
      return value;
    }

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  parseSet(value) {
    return typeof value === 'object' && this.options.smartParse
      ? JSON.stringify(value)
      : value;
  }

  setItem(key, value, expires = null) {
    if (!this.modern) {
      return Cookies.set(key, this.parseSet(value), {
        expires: expires || this.defaultExpiry,
        secure: true,
      });
    }

    const storage = this.options.session ? sessionStorage : localStorage;

    if (expires) {
      return storage.setItem(
        key,
        JSON.stringify({
          _storageDate: Math.round(new Date().getTime() / 1000),
          _storageExpires: expires * 86400,
          data: value,
        })
      );
    }

    return storage.setItem(key, this.parseSet(value));
  }

  removeItem(key) {
    if (!this.modern) return Cookies.remove(key);
    return this.options.session
      ? sessionStorage.removeItem(key)
      : localStorage.removeItem(key);
  }
}

const createStorage = options => new Storage(options);

export { createStorage, Storage };
export default createStorage;
