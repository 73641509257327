import Base from './base';

export default class Synonyms extends Base {
  $relay() {
    return 'exuberant-witness';
  }

  async index(params) {
    return this.$request('get', 'synonyms', {
      params,
    });
  }

  async create({ active = true, synonyms, root = null }) {
    return this.$request('post', 'synonyms', {
      root,
      active,
      synonyms,
    });
  }

  async read(uuid) {
    return this.$request('get', `synonyms/${uuid}`);
  }

  async update(uuid, { active, synonyms, root = null }) {
    return this.$request('put', `synonyms/${uuid}`, {
      active,
      root,
      synonyms,
    });
  }

  async delete(uuid) {
    return this.$request('delete', `synonyms/${uuid}`);
  }
}
