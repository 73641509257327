import axios from '@/etc/axios';

export default class Base {
  constructor(Api) {
    if (Api) {
      this.Api = Api;
    }

    this.$configureAxios();
  }

  $relay() {
    return '';
  }

  $configureAxios() {
    this.$axios = axios.createClient({
      baseURL: `/api/${this.$relay() ? `relays/${this.$relay()}` : ''}`,
      retry: { attempts: 1 },
    });
  }

  async $request(method, ...args) {
    return await this.$axios[method](...args);
  }
}
