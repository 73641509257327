import '@/etc/setup';

import '@/app';
import '@/api';
import '@/stores';
import '@/router';
import '@/directives';
import '@/mixins';
import '@/components';

import '@/etc/mount';
