import axios from 'axios';
import * as rax from 'retry-axios';
import _ from 'lodash';

class Axios {
  constructor() {
    this.defaults = {
      retry: {
        attempts: 3,
        httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS', 'DELETE', 'PUT', 'POST'],
        onRetryAttempt: false,
      },
    };
  }

  createClient(opts) {
    // set up options
    const options = _.merge({}, this.defaults, opts || {});

    // create axios client
    const client = axios.create(_.omit(options, ['retry', 'limits']));

    // configure rax
    rax.attach(client);
    client.defaults.raxConfig = {
      instance: client,
      retry: options.retry.attempts,
      onRetryAttempt: async (...args) => {
        if (options.retry.onRetryAttempt) {
          await options.onRetryAttempt(...args);
        }
      },
    };

    // send back client
    return client;
  }
}

export default new Axios();
