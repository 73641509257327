import app from '@/app';
import { defineAsyncComponent } from 'vue';

app.component(
  'WidgetIndexes',
  defineAsyncComponent(() => import('@/components/Widgets/Indexes'))
);
app.component(
  'WidgetCollections',
  defineAsyncComponent(() => import('@/components/Widgets/Collections'))
);
app.component(
  'WidgetSynonyms',
  defineAsyncComponent(() => import('@/components/Widgets/Synonyms'))
);
app.component(
  'WidgetOosReport',
  defineAsyncComponent(() => import('@/components/Widgets/OosReport'))
);
app.component(
  'WidgetBrandsByDomainReport',
  defineAsyncComponent(() =>
    import('@/components/Widgets/BrandsByDomainReport')
  )
);
app.component(
  'WidgetSoldUnsoldReport',
  defineAsyncComponent(() => import('@/components/Widgets/SoldUnsoldReport'))
);
