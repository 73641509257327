import Base from './base';

export default class ContentItems extends Base {
  $relay() {
    return 'nexus';
  }

  async index(params = {}) {
    return this.$request('get', 'content-items', {
      params,
    });
  }

  async read(uuid) {
    return this.$request('get', `content-items/${uuid}`);
  }

  async types() {
    return this.$request('get', 'content-items/types');
  }
}
