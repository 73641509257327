import createStorage from '@digittl/storage';

// Set vue options
window.__VUE_OPTIONS_API__ = true;
window.__VUE_PROD_DEVTOOLS__ = false;

// Manage local storage migration
const storage = createStorage();

if (storage.getItem('migration') !== 1) {
  storage.removeItem('user');
  storage.removeItem('form');
  storage.removeItem('field');
  storage.removeItem('domain');
  storage.setItem('migration', 1);
}
