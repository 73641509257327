import Base from './base';

export default class User extends Base {
  async read() {
    return this.$request('get', 'user');
  }

  login(redirect = window.location.href) {
    window.location = `/login?redirect=${encodeURIComponent(redirect)}`;
  }

  logout() {
    window.location = '/logout';
  }
}
