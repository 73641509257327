import Base from './base';

export default class Reviews extends Base {
  $relay() {
    return 'reviews';
  }

  async index(uuid = null, page = 1) {
    return this.$request(
      'get',
      `reviews?filter[productUuid]=${uuid}&page=${page}`
    );
  }

  async read(uuid) {
    return this.$request('get', `reviews/${uuid}`);
  }
}
