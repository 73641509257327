import api from '@/api';
import { ref, computed } from 'vue';
import { defineStore } from 'pinia';

export default defineStore(
  'user',
  () => {
    const user = ref(null);
    const authenticated = computed(() => !!user.value || false);
    const fetch = async () => {
      try {
        const response = await api.user.read();
        user.value = response.data;
      } catch {
        user.value = null;
      }
    };
    const login = (a, redirect) => {
      api.user.login(redirect);
    };
    return {
      user,
      authenticated,
      fetch,
      login,
    };
  },
  {
    persist: true,
  }
);
