const createPromiseGate = () => {
  const ext = {};
  const promise = new Promise((resolve, reject) => {
    ext.resolve = resolve;
    ext.reject = reject;
  });

  Object.assign(promise, ext);

  return promise;
};

module.exports = createPromiseGate;
