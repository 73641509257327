import './Button';
import './Charts';
import './Icons';
import './Thumbs';
import './Forms';

import { defineAsyncComponent } from 'vue';
import app from '@/app';

app.component(
  'Logo',
  defineAsyncComponent(() => import('@/components/Utilities/Logo'))
);

app.component(
  'Box',
  defineAsyncComponent(() => import('@/components/Utilities/Box'))
);

app.component(
  'Loader',
  defineAsyncComponent(() => import('@/components/Utilities/Loader'))
);

app.component(
  'Tooltip',
  defineAsyncComponent(() => import('@/components/Utilities/Tooltip'))
);

app.component(
  'VueCarousel',
  defineAsyncComponent(() => import('@digittl/vue-carousel'))
);

app.component(
  'Badge',
  defineAsyncComponent(() => import('@/components/Utilities/Badge'))
);

app.component(
  'Pagination',
  defineAsyncComponent(() => import('@/components/Utilities/Pagination'))
);

app.component(
  'PaginationNexus',
  defineAsyncComponent(() => import('@/components/Utilities/PaginationNexus'))
);

app.component(
  'Editor',
  defineAsyncComponent(() => import('@/components/Utilities/Editor'))
);

app.component(
  'DropZone',
  defineAsyncComponent(() => import('@/components/Utilities/DropZone'))
);

app.component(
  'ContextMenu',
  defineAsyncComponent(() => import('@/components/Utilities/ContextMenu'))
);
