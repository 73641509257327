import _ from 'lodash';
import { reactive } from 'vue';
import config from '@/app.config.js';

const breakPoints = {
  init() {
    this.container = reactive({});

    this.screens = this.transform({
      zero: '0px',
      ...config.screens,
    });

    this.categories = {
      mobile: ['zero', 'xs', 'xsplus', 'xslandscape', 'xspluslandscape'],
      desktop: ['sm', 'md', 'lg', 'xl'],
      touch: ['zero', 'xs', 'xsplus', 'xslandscape', 'xspluslandscape', 'sm'],
      notTouch: ['md', 'lg', 'xl'],
    };

    this.detect();
    this.bind();

    return this.container;
  },
  transform(obj) {
    // eslint-disable-next-line no-param-reassign
    obj = _.map(obj, (val, key) => [
      key,
      Number(val.substring(0, val.length - 2)),
    ]);
    return _.sortBy(obj, 1);
  },
  detect() {
    let current = '';

    _.some(this.screens, ([key, minWidth]) => {
      current = window.innerWidth >= minWidth ? key : current;
      return current !== key;
    });

    this.container.current = current;

    _.each(this.screens, ([key]) => {
      this.container[key] = key === current;
    });

    _.each(this.categories, (screens, category) => {
      this.container[category] = _.some(
        screens,
        screen => this.container[screen]
      );
    });
  },
  bind() {
    window.addEventListener('resize', () => this.detect());
  },
};

export default breakPoints.init();
