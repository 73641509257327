import Base from './base';

export default class Products extends Base {
  $relay() {
    return 'nexus';
  }

  async index(params = {}) {
    return this.$request('get', 'products', {
      params,
    });
  }

  async read(uuid) {
    return this.$request('get', `products/${uuid}`);
  }

  async readBySku(domain, sku) {
    return this.$request('get', `products/by/sku/${sku}?domain=${domain}`);
  }
}
