import foreground from './foreground';
import _ from 'lodash';

const mixin = {
  computed: {
    foregroundFocused() {
      return _.last(foreground.layers);
    },
  },
};

export default mixin;
