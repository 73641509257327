import { defineAsyncComponent } from 'vue';
import app from '@/app';

app.component(
  'SiteHeader',
  defineAsyncComponent(() => import('@/components/Structure/SiteHeader'))
);

app.component(
  'Sidebar',
  defineAsyncComponent(() => import('@/components/Structure/Sidebar'))
);

app.component(
  'MobileSidebar',
  defineAsyncComponent(() =>
    import('@/components/Structure/Sidebar/MobileSidebar')
  )
);

app.component(
  'SiteFooter',
  defineAsyncComponent(() => import('@/components/Structure/SiteFooter'))
);
