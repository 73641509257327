import app from '@/app';
import { defineAsyncComponent } from 'vue';

app.component(
  'ButtonAlert',
  defineAsyncComponent(() =>
    import('@/components/Utilities/Button/ButtonAlert')
  )
);
app.component(
  'ButtonAsync',
  defineAsyncComponent(() =>
    import('@/components/Utilities/Button/ButtonAsync')
  )
);
app.component(
  'ButtonConfirm',
  defineAsyncComponent(() =>
    import('@/components/Utilities/Button/ButtonConfirm')
  )
);
app.component(
  'ButtonTooltip',
  defineAsyncComponent(() =>
    import('@/components/Utilities/Button/ButtonTooltip')
  )
);
app.component(
  'ButtonCopy',
  defineAsyncComponent(() => import('@/components/Utilities/Button/ButtonCopy'))
);
app.component(
  'Toggle',
  defineAsyncComponent(() => import('@/components/Utilities/Button/Toggle'))
);
