import Base from './base';

export default class Fitment extends Base {
  $relay() {
    return 'mx-api';
  }

  async indexExclusions(params) {
    return await this.$request('get', 'admin/fitment/exclusions', {
      params,
    });
  }

  async checkExclusions(uuids) {
    return await this.$request('post', 'admin/fitment/exclusions/check', {
      uuids,
    });
  }

  async createExclusion(uuid) {
    return await this.$request('post', 'admin/fitment/exclusions', { uuid });
  }

  async destroyExclusion(uuid) {
    return await this.$request('delete', `admin/fitment/exclusions/${uuid}`);
  }

  async indexTimings(params) {
    return await this.$request('get', 'admin/fitment/timings', {
      params,
    });
  }

  async checkTimings(uuids) {
    return await this.$request('post', 'admin/fitment/timings/check', {
      uuids,
    });
  }

  async readTiming(uuid) {
    return await this.$request('get', `admin/fitment/timings/${uuid}`);
  }

  async createTiming(payload) {
    return await this.$request('post', 'admin/fitment/timings', payload);
  }

  async updateTiming(uuid, payload) {
    return await this.$request('put', `admin/fitment/timings/${uuid}`, payload);
  }

  async destroyTiming(uuid) {
    return await this.$request('delete', `admin/fitment/timings/${uuid}`);
  }

  async indexReports(params) {
    return await this.$request('get', 'admin/fitment/reports', {
      params,
    });
  }
}
