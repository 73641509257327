import Base from './base';

export default class SizeGuides extends Base {
  $relay() {
    return 'size-guides';
  }

  async index(params = {}) {
    return this.$request('get', 'groups', {
      params,
    });
  }

  async create(payload) {
    return this.$request('post', 'groups', payload);
  }

  async read(uuid) {
    return this.$request('get', `groups/${uuid}`);
  }

  async update(uuid, payload) {
    return this.$request('put', `groups/${uuid}`, payload);
  }

  async destroy(uuid) {
    return this.$request('delete', `groups/${uuid}`);
  }

  async assetsIndex(params = {}) {
    return this.$request('get', 'assets', {
      params,
    });
  }

  async assetCreate(payload) {
    return this.$request('post', 'assets', payload);
  }

  async assetRead(uuid) {
    return this.$request('get', `assets/${uuid}`);
  }

  async assetUpdate(uuid, payload) {
    return this.$request('put', `assets/${uuid}`, payload);
  }

  async assetDestroy(uuid) {
    return this.$request('delete', `assets/${uuid}`);
  }

  async measuringGuidesIndex(params = {}) {
    return this.$request('get', 'measuring-guides', {
      params,
    });
  }

  async measuringGuideCreate(payload) {
    return this.$request('post', 'measuring-guides', payload);
  }

  async measuringGuideRead(uuid) {
    return this.$request('get', `measuring-guides/${uuid}`);
  }

  async measuringGuideUpdate(uuid, payload) {
    return this.$request('put', `measuring-guides/${uuid}`, payload);
  }

  async measuringGuideDestroy(uuid) {
    return this.$request('delete', `measuring-guides/${uuid}`);
  }
}
