<template>
  <login v-if="!authenticated" />
  <div v-else class="flex min-h-screen w-full text-sm">
    <sidebar
      v-if="breakPoints.lg || breakPoints.xl"
      class="fixed top-0 left-0 my-4 ml-4 w-72"
      :class="{ 'blur-sm': blur }"
    />
    <mobile-sidebar v-else :class="{ 'blur-sm': blur }" />
    <div :class="{ 'blur-sm': blur }" class="page">
      <router-view :key="$route.path"></router-view>
      <site-footer class="mt-auto" />
    </div>
  </div>
  <modal-target />
</template>

<script>
import _ from 'lodash';
import { defineAsyncComponent } from 'vue';
import useUserStore from '@/stores/user';
import app from '@/app';
import breakPoints from '@/etc/break-points';
import { appMixin } from '@digittl/foreground';

export default {
  components: {
    Login: defineAsyncComponent(() => import('@/components/Pages/Login')),
  },
  mixins: [appMixin],
  setup() {
    return { userStore: useUserStore() };
  },
  data: () => ({
    breakPoints,
  }),
  computed: {
    authenticated() {
      return this.userStore.authenticated;
    },
    blur() {
      return !!_.get(this.foregroundFocused, 'attrs.blur');
    },
  },
  created() {
    // fetch user data from api
    this.fetchUser();

    // bind shortcuts
    document.addEventListener(
      'keydown',
      _.debounce(e => this.shortCuts(e), 50)
    );
  },
  methods: {
    async fetchUser() {
      await this.userStore.fetch();

      if (this.authenticated) {
        // set up a ping to check auth
        setInterval(() => this.ping(), 10 * 1000);
      }
    },
    async ping() {
      // re-fetch authentication status & user details
      await this.userStore.fetch();

      // run login action if no longer authenticated
      if (!this.authenticated) {
        this.userStore.login();
      }
    },
    shortCuts(e) {
      if (e.key === 'k' && (e.ctrlKey || e.metaKey)) {
        app.$events.emit('launch-search-modal');
      }
    },
  },
};
</script>
