import app from '@/app';
import { defineAsyncComponent } from 'vue';

app.component(
  'ThumbGrid',
  defineAsyncComponent(() => import('@/components/Utilities/Thumbs/ThumbGrid'))
);

app.component(
  'ProductThumb',
  defineAsyncComponent(() =>
    import('@/components/Utilities/Thumbs/ProductThumb')
  )
);
