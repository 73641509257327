import Base from './base';

export default class Aliases extends Base {
  $relay() {
    return 'exuberant-witness';
  }

  async index() {
    return this.$request('get', 'aliases');
  }

  async read(name) {
    return this.$request('get', `aliases/${name}`);
  }

  async delete(name) {
    return this.$request('delete', `aliases/${name}`);
  }

  async upsert(alias, collection) {
    return this.$request('put', `aliases/${alias}`, {
      collection,
    });
  }
}
