import { defineStore } from 'pinia';
import { reactive, computed } from 'vue';
import _ from 'lodash';
import { useRoute } from 'vue-router';

export default defineStore('form', () => {
  const forms = reactive({});
  const route = computed(() => useRoute());
  const pageKey = computed(() => route.value.path);

  const fields = (defs = {}, { customKey = '' } = {}) => {
    const key = customKey || pageKey.value;

    // assign the defaults
    forms[key] = {
      fields: _.cloneDeep(defs || {}),
      errors: {},
      $errors: {},
      defaults: defs || {},
      showValidation: false,
      resetting: false,
    };

    return forms[key].fields;
  };

  const form = ({ customKey = '' } = {}) => {
    const key = customKey || pageKey.value;
    return forms[key];
  };

  const getErrors = ({ id = '', customKey = '' } = {}) => {
    const key = customKey || pageKey.value;

    if (!forms[key]) {
      fields({}, { customKey: key });
    }

    if (id) {
      return forms[key].errors ? forms[key].errors[id] || [] : [];
    } else {
      return _.flatten(_.values(forms[key].errors || {}));
    }
  };

  const setErrors = (id, errs = [], { customKey = '', init = false } = {}) => {
    const key = customKey || pageKey.value;

    if (!forms[key]) {
      fields({}, { customKey: key });
    }

    forms[key].$errors = forms[key].$errors || {};
    forms[key].$errors[id] = errs || [];

    if (!init) {
      forms[key].errors = forms[key].errors || {};
      forms[key].errors[id] = errs || [];
    }
  };

  const reset = ({ customKey = '' } = {}) => {
    const key = customKey || pageKey.value;

    if (!forms[key]) {
      fields({}, { customKey: key });
    }

    forms[key].fields = _.cloneDeep(forms[key].defaults);
    forms[key].errors = {};
    forms[key].$errors = {};
    forms[key].showValidation = false;
    forms[key].resetting = true;

    setTimeout(() => {
      forms[key].resetting = false;
    });
  };

  const isValid = ({ customKey = '' } = {}) => {
    const key = customKey || pageKey.value;

    const isEqual = _.isEqual(forms[key].fields, forms[key].defaults);
    const errs = _.flatten(_.values(forms[key].$errors || {}));
    const isValid = !isEqual && errs.length == 0;

    return isValid;
  };

  const isResetting = ({ customKey = '' } = {}) => {
    const key = customKey || pageKey.value;
    return !!forms[key]?.resetting;
  };

  const showValidation = (val = true, { customKey = '' } = {}) => {
    const key = customKey || pageKey.value;

    if (!forms[key]) {
      fields({}, { customKey: key });
    }

    forms[key].showValidation = val;
  };

  return {
    forms,
    form,
    showValidation,
    isValid,
    reset,
    isResetting,
    fields,
    getErrors,
    setErrors,
  };
});
