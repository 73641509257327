import { defineAsyncComponent } from 'vue';

export default [
  {
    name: 'home',
    path: '/',
    component: () => import('@/components/Pages/Home'),
    meta: {
      nav: { icon: 'home' },
    },
  },
  {
    name: 'products',
    path: '/products',
    component: () => import('@/components/Structure/PageParent'),
    meta: {
      default: defineAsyncComponent(() =>
        import('@/components/Pages/Products')
      ),
      nav: { icon: 'products', visitedOnly: true },
    },
    children: [
      {
        name: 'products.product',
        meta: { title: 'Product' },
        path: '/products/:domain/:sku',
        component: () => import('@/components/Pages/Products/Product'),
      },
    ],
  },
  {
    name: 'content-items',
    path: '/content-items',
    component: () => import('@/components/Structure/PageParent'),
    meta: {
      title: 'Content Items',
      nav: { icon: 'content-items', visitedOnly: true },
      default: defineAsyncComponent(() =>
        import('@/components/Pages/ContentItems')
      ),
    },
    children: [
      {
        name: 'content-items.content-item',
        path: '/content-items/:uuid',
        meta: { title: 'Content Item' },
        component: () => import('@/components/Pages/ContentItems/ContentItem'),
      },
    ],
  },
  {
    name: 'customers',
    path: '/customers',
    component: () => import('@/components/Structure/PageParent'),
    meta: {
      nav: { icon: 'people', visitedOnly: true },
      default: defineAsyncComponent(() =>
        import('@/components/Pages/Customers')
      ),
    },
    children: [
      {
        name: 'customers.customer',
        meta: { title: 'Customer' },
        path: '/customers/:domain/:username',
        component: () => import('@/components/Pages/Customers/Customer'),
      },
    ],
  },
  {
    name: 'orders',
    path: '/orders',
    component: () => import('@/components/Structure/PageParent'),
    meta: {
      nav: { icon: 'orders', visitedOnly: true },
      default: defineAsyncComponent(() => import('@/components/Pages/Orders')),
    },
    children: [
      {
        name: 'orders.order',
        meta: { title: 'Order' },
        path: '/orders/:domain/:id',
        component: () => import('@/components/Pages/Orders/Order'),
      },
    ],
  },
  {
    name: 'size-guides',
    path: '/size-guides',
    component: () => import('@/components/Structure/PageParent'),
    meta: {
      title: 'Size Guides',
      nav: { icon: 'sizing' },
      default: defineAsyncComponent(() =>
        import('@/components/Pages/SizeGuides/Groups')
      ),
    },
    children: [
      {
        name: 'size-guides.new',
        path: 'new',
        component: () => import('@/components/Structure/PageParent'),
        meta: {
          title: 'New Group',
          default: defineAsyncComponent(() =>
            import('@/components/Pages/SizeGuides/EditGroup')
          ),
          nav: { visitedOnly: true },
        },
      },
      {
        name: 'size-guides.edit',
        path: ':uuid',
        component: () => import('@/components/Structure/PageParent'),
        meta: {
          title: 'Edit Group',
          default: defineAsyncComponent(() =>
            import('@/components/Pages/SizeGuides/EditGroup')
          ),
        },
      },
      {
        name: 'size-guides.measuring-guides',
        path: 'measuring-guides',
        component: () => import('@/components/Structure/PageParent'),
        meta: {
          title: 'Measuring Guides',
          default: defineAsyncComponent(() =>
            import('@/components/Pages/SizeGuides/MeasuringGuides')
          ),
          nav: {},
        },
        children: [
          {
            name: 'size-guides.measuring-guides.new',
            path: 'new',
            component: () => import('@/components/Structure/PageParent'),
            meta: {
              title: 'New Measuring Guide',
              default: defineAsyncComponent(() =>
                import('@/components/Pages/SizeGuides/EditMeasuringGuide')
              ),
              nav: { visitedOnly: true },
            },
          },
          {
            name: 'size-guides.measuring-guides.edit',
            path: ':uuid',
            component: () => import('@/components/Structure/PageParent'),
            meta: {
              title: 'Edit Measuring Guide',
              default: defineAsyncComponent(() =>
                import('@/components/Pages/SizeGuides/EditMeasuringGuide')
              ),
            },
          },
        ],
      },
      {
        name: 'size-guides.assets',
        path: 'assets',
        component: () => import('@/components/Structure/PageParent'),
        meta: {
          title: 'Assets',
          default: defineAsyncComponent(() =>
            import('@/components/Pages/SizeGuides/Assets')
          ),
          nav: {},
        },
        children: [
          {
            name: 'size-guides.assets.new',
            path: 'new',
            component: () => import('@/components/Structure/PageParent'),
            meta: {
              title: 'New Asset',
              default: defineAsyncComponent(() =>
                import('@/components/Pages/SizeGuides/EditAsset')
              ),
              nav: { visitedOnly: true },
            },
          },
          {
            name: 'size-guides.assets.edit',
            path: ':uuid',
            component: () => import('@/components/Structure/PageParent'),
            meta: {
              title: 'Edit Asset',
              default: defineAsyncComponent(() =>
                import('@/components/Pages/SizeGuides/EditAsset')
              ),
            },
          },
        ],
      },
    ],
  },
  {
    name: 'guaranteed-fitment',
    path: '/guaranteed-fitment',
    component: () => import('@/components/Structure/PageParent'),
    meta: {
      title: 'Guaranteed Fitment',
      nav: { icon: 'spanner' },
      default: defineAsyncComponent(() =>
        import('@/components/Pages/GuaranteedFitment')
      ),
    },
    children: [
      {
        name: 'guaranteed-fitment.reports',
        path: 'reports',
        component: () => import('@/components/Pages/GuaranteedFitment/Reports'),
        meta: { title: 'Reports', nav: {} },
      },
      {
        name: 'guaranteed-fitment.exclusions',
        path: 'exclusions',
        component: () =>
          import('@/components/Pages/GuaranteedFitment/Exclusions'),
        meta: { title: 'Exclusions', nav: {} },
      },
      {
        name: 'guaranteed-fitment.timings',
        path: 'timings',
        component: () => import('@/components/Structure/PageParent'),
        meta: {
          title: 'Timings',
          nav: {},
          default: defineAsyncComponent(() =>
            import('@/components/Pages/GuaranteedFitment/Timings')
          ),
        },
        children: [
          {
            name: 'guaranteed-fitment.timings.edit',
            path: ':uuid',
            component: () =>
              import('@/components/Pages/GuaranteedFitment/EditTiming'),
            meta: { title: 'Edit Timing' },
          },
        ],
      },
    ],
  },
  {
    name: 'typesense.synonyms',
    path: '/typesense',
    component: () => import('@/components/Structure/PageParent'),
    meta: {
      title: 'Typesense',
      nav: { icon: 'settings' },
      default: defineAsyncComponent(() =>
        import('@/components/Pages/Typesense')
      ),
    },
    children: [
      {
        name: 'typesense.synonyms.new',
        path: 'new-synonym',
        meta: { title: 'New Synonym', nav: { visitedOnly: true } },
        component: () => import('@/components/Pages/Typesense/NewSynonym'),
      },
      {
        name: 'typesense.synonyms.synonym',
        path: ':uuid',
        meta: { title: 'Synonyms' },
        component: () => import('@/components/Pages/Typesense/Synonym'),
      },
    ],
  },
  {
    name: 'product-groups',
    path: '/product-groups',
    component: () => import('@/components/Structure/PageParent'),
    meta: {
      title: 'Product Groups',
      nav: { icon: 'products' },
      default: defineAsyncComponent(() =>
        import('@/components/Pages/ProductGroups/Groups')
      ),
    },
    children: [
      {
        name: 'product-groups.new',
        path: 'new-group',
        meta: { title: 'New Group', nav: { visitedOnly: true } },
        component: () => import('@/components/Pages/ProductGroups/EditGroup'),
      },
      {
        name: 'product-groups.edit',
        path: ':uuid',
        meta: { title: 'Edit Group' },
        component: () => import('@/components/Pages/ProductGroups/EditGroup'),
      },
    ],
  },
  {
    name: 'showroom',
    path: '/showroom',
    component: () => import('@/components/Structure/PageParent'),
    meta: {
      title: 'Showroom',
      nav: { icon: 'showroom' },
      default: defineAsyncComponent(() =>
        import('@/components/Pages/CashCounter/CashCounter')
      ),
    },
  },
  {
    name: 'yotpo',
    path: '/yotpo',
    component: () => import('@/components/Structure/PageParent'),
    meta: {
      title: 'Yotpo',
      nav: { icon: 'settings' },
      default: defineAsyncComponent(() => import('@/components/Pages/Yotpo')),
    },
    children: [
      {
        name: 'yotpo.exclusions',
        path: 'exclusions',
        meta: { title: 'Exclusions', nav: {} },
        component: () => import('@/components/Pages/Yotpo/Exclusions'),
      },
      {
        name: 'yotpo.exclusions.add',
        path: 'add',
        meta: { title: 'Add Exclusion', nav: { visitedOnly: true } },
        component: () =>
          import('@/components/Pages/Yotpo/Exclusions/AddExclusions'),
      },
    ],
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('@/components/Pages/User'),
  },
  {
    name: '404',
    path: '/404',
    component: () => import('@/components/Pages/404'),
    meta: { title: '404!!' },
  },
  {
    path: '/:pathMatch(.*)*',
    beforeEnter: () => {
      window.location = '/404';
    },
  },
  {
    name: 'testpage',
    path: '/testpage',
    component: () => import('@/components/Pages/TestPage'),
    meta: {
      nav: { visitedOnly: true },
    },
  },
];
