import Base from './base';
import Queue from '@digittl/promise-queue';

export default class Search extends Base {
  constructor() {
    super();
    this.queue = new Queue({ concurrency: 1 });
  }

  async query(collection, { q = '', options = {} } = {}, axiosOptions = {}) {
    const request = this.$request(
      'post',
      `search/${collection}`,
      { q, options },
      axiosOptions
    );

    return this.queue.add(async () => {
      return await request;
    });
  }

  async queryAll(
    q,
    {
      collections = [],
      options = {},
      merge = true,
      sortMerged = true,
      trimMerged = true,
    } = {},
    axiosOptions = {}
  ) {
    const request = this.$request(
      'post',
      'search',
      {
        q,
        options,
        collections,
        merge,
        sortMerged,
        trimMerged,
      },
      axiosOptions
    );

    return this.queue.add(async () => {
      return await request;
    });
  }
}
