import Base from './base';

export default class Collections extends Base {
  $relay() {
    return 'exuberant-witness';
  }

  async index() {
    return this.$request('get', 'collections');
  }

  async read(name) {
    return this.$request('get', `collections/${name}`);
  }

  async delete(name) {
    return this.$request('delete', `collections/${name}`);
  }

  async create({ name, fields, default_sorting_field }) {
    return this.$request('post', 'collections', {
      name,
      fields,
      default_sorting_field,
    });
  }
}
