import Base from './base';

export default class Products extends Base {
  $relay() {
    return 'reports';
  }

  async index(params = {}) {
    return this.$request('get', 'reports', {
      params,
    });
  }

  async read(name) {
    return this.$request('get', `reports/${name}`);
  }
}
