import Base from './base';

export default class Orders extends Base {
  $relay() {
    return 'nexus';
  }

  async read(domain, id) {
    return this.$request('get', `orders/by/order-id/${id}?domain=${domain}`);
  }
}
