import createPromiseGate from '@digittl/promise-gate';

class Queue {
  constructor(options = {}) {
    this.options = {
      paused: false,
      concurrency: false,
      ...options,
    };

    this.paused = this.options.paused;
    this.waiting = [];
    this.running = 0;
  }

  updateConcurrency(concurrency) {
    this.options.concurrency = concurrency;

    if (!this.paused) {
      this.waiting.slice().forEach(() => this.next());
    }
  }

  async add(fn) {
    const gate = createPromiseGate();
    this.waiting.push(gate);

    this.next();

    await gate;
    const result = await (async () => fn())();

    this.running -= 1;
    this.next();

    return result;
  }

  next() {
    if (
      this.paused ||
      !this.waiting.length ||
      this.running === this.options.concurrency
    ) {
      return;
    }

    this.running += 1;
    this.waiting.shift().resolve();
  }

  start() {
    this.paused = false;
    this.waiting.slice().forEach(() => this.next());
  }

  pause() {
    this.paused = true;
  }

  reset() {
    this.pause();
    this.waiting = [];
  }

  bind(context, name) {
    if (Array.isArray(name)) {
      name.forEach(n => this.bind(context, n));
      return;
    }

    const method = context[name];
    // eslint-disable-next-line no-param-reassign
    context[name] = (...args) => this.add(() => method.call(context, ...args));
  }
}

module.exports = Queue;
