import _ from 'lodash';
import app from '@/app';

const bindings = [];

app.directive('clickStop', {
  mounted(el, { value }) {
    if (value === false) {
      return;
    }

    const click = e => e.stopPropagation();

    el.addEventListener('click', click);
    bindings.push({ el, click });
  },
  unbind(el, { value }) {
    if (value === false) {
      return;
    }

    // find the binding
    const { click } = _.find(bindings, { el });

    // unbind
    el.removeEventListener('click', click);

    // clear the binding from storage
    _.remove(bindings, { el });
  },
});
