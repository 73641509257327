import { createApp } from 'vue';
import App from '@/components/App';
import Emitter from 'eventemitter3';

// create app instance
const app = createApp(App);

// create event bus
app.$events = new Emitter();

// export for re-use
export default app;
