import { defineAsyncComponent } from 'vue';
import app from '@/app';

app.component(
  'IndexTemplate',
  defineAsyncComponent(() => import('./IndexTemplate.vue'))
);

app.component(
  'EditTemplate',
  defineAsyncComponent(() => import('./EditTemplate.vue'))
);

app.component(
  'EditTemplateSection',
  defineAsyncComponent(() => import('./EditTemplateSection.vue'))
);
