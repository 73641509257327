import _ from 'lodash';
import { reactive } from 'vue';

class Foreground {
  constructor() {
    this.bindings = reactive([]);
    this.layers = reactive([]);
  }

  get focused() {
    return _.last(this.layers);
  }

  /**
   * Add a component to the foreground and give it focus
   * @param component
   * @param bindings
   */
  add(component, bindings = [], attrs = {}) {
    this.layers.push({ component, bindings, attrs });

    _.each(bindings, ({ event, callback }) => {
      this._bind({ component, event, callback });
    });
  }

  /**
   * Remove a component from the foreground (or any other sub layers)
   * @param component
   */
  remove(component) {
    if (!_.find(this.layers, ({ component: c }) => c === component)) {
      return;
    }

    _.remove(this.layers, ({ component: c }) => c === component);
    this._unbind(component);
  }

  /**
   * Bring a component that is already in the list of sub layers to the foreground
   * @param component
   */
  focus(component) {
    const layer = _.find(this.layers, ({ component: c }) => c === component);
    _.remove(this.layers, l => l === layer);
    this.layers.push(layer);
  }

  _bind({ component, event, callback }) {
    const handler = e =>
      this.focused && this.focused.component === component && callback(e);
    window.addEventListener(event, handler);

    this.bindings.push({
      event,
      component,
      handler,
    });
  }

  _unbind(component) {
    const bindings = _.filter(
      this.bindings,
      ({ component: c }) => c === component
    );

    _.each(bindings, binding => {
      window.removeEventListener(binding.event, binding.handler);
      _.remove(this.bindings, b => b === binding);
    });
  }
}

export default new Foreground();
