import app from '@/app';
import { defineAsyncComponent } from 'vue';

app.component(
  'Modal',
  defineAsyncComponent(() => import('@/components/Modal/Modal'))
);
app.component(
  'ModalDialog',
  defineAsyncComponent(() => import('@/components/Modal/ModalDialog'))
);
app.component(
  'ModalTarget',
  defineAsyncComponent(() => import('@/components/Modal/ModalTarget'))
);
