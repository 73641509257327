import Base from './base';
import User from './user';
import Search from './search';
import Products from './products';
import Domains from './domains';
import ContentItems from './content-items';
import Customers from './customers';
import Orders from './orders';
import Collections from './collections';
import Aliases from './aliases';
import Synonyms from './synonyms';
import Reviews from './reviews';
import Reports from './reports';
import SizeGuides from './size-guides';
import Fitment from './fitment';
import ProductGroups from './product-groups';
import Yotpo from './yotpo';

class Api extends Base {
  constructor() {
    super();
    this.user = new User(this);
    this.search = new Search(this);
    this.products = new Products(this);
    this.domains = new Domains(this);
    this.contentItems = new ContentItems(this);
    this.customers = new Customers(this);
    this.orders = new Orders(this);
    this.collections = new Collections(this);
    this.aliases = new Aliases(this);
    this.synonyms = new Synonyms(this);
    this.reviews = new Reviews(this);
    this.reports = new Reports(this);
    this.sizeGuides = new SizeGuides(this);
    this.fitment = new Fitment(this);
    this.productGroups = new ProductGroups(this);
    this.yotpo = new Yotpo(this);
  }
}

const api = new Api();
window.api = api;

export default api;
