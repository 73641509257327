import Base from './base';
import async from 'async';

export default class Yotpo extends Base {
  $relay() {
    return 'yotpo';
  }

  async exclusionsIndex(params) {
    return this.$request('get', 'admin/exclusions', {
      params,
    });
  }

  async exclusionsCreate(uuid) {
    // test if uuid is a valid uuid
    if (!uuid.match(/^[a-f\d]{8}(-[a-f\d]{4}){4}[a-f\d]{8}$/i)) {
      // uuid is sku
      // look up product uuid

      const products = await this.Api.products.index({
        'filter[sku]': uuid,
      });

      await async.each(products.data?.results, async product => {
        this.exclusionsCreate(product.uuid);
      });
    } else {
      return this.$request('post', 'admin/exclusions', {
        uuid,
      });
    }
  }

  async exclusionsRead(uuid) {
    return this.$request('get', `admin/exclusions/${uuid}`);
  }

  async exclusionsDestroy(uuid) {
    return this.$request('delete', `admin/exclusions/${uuid}`);
  }
}
