import { defineAsyncComponent } from 'vue';
import app from '@/app';

app.component(
  'FieldWrapper',
  defineAsyncComponent(() => import('./FieldWrapper.vue'))
);

app.component(
  'ComboField',
  defineAsyncComponent(() => import('./ComboField.vue'))
);

app.component(
  'SelectField',
  defineAsyncComponent(() => import('./SelectField.vue'))
);

app.component(
  'InputField',
  defineAsyncComponent(() => import('./InputField.vue'))
);

app.component(
  'ToggleField',
  defineAsyncComponent(() => import('./ToggleField.vue'))
);

app.component(
  'ErrorPopover',
  defineAsyncComponent(() => import('./ErrorPopover.vue'))
);

app.component(
  'ContentItemField',
  defineAsyncComponent(() => import('./ContentItemField.vue'))
);

app.component(
  'Rule',
  defineAsyncComponent(() => import('./Rule.vue'))
);
