import Base from './base';

export default class Domains extends Base {
  $relay() {
    return 'nexus';
  }

  async index() {
    return this.$request('get', `domains`);
  }

  async read(code) {
    return this.$request('get', `domains/${code}`);
  }
}
