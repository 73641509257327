import _ from 'lodash';

export default {
  computed: {
    $route() {
      return this.$router.currentRoute.value;
    },
  },
  methods: {
    updateRoute(query) {
      this.$router.push({
        name: this.$route.name,
        query: _.merge({}, this.$route.query, query),
      });
    },
    updatePage(page) {
      this.updateRoute({ page });
    },
  },
};
